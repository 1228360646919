'use client';

import { useActiveQuestionId } from '@mentimeter/core-hooks/use-ids';
import { createPresentationSeriesCompatibilityLayer } from '@mentimeter/compatibility/presentation';
import { questionsCacheKey, seriesCacheKey } from '@mentimeter/core-hooks';
import { getSlideById } from '@mentimeter/editor-essentials/calculations';
import { VIEW_ONLY_DISABLED_MESSAGE } from '@mentimeter/editor-essentials/constants';
import { useEditorRouter } from '@mentimeter/editor-essentials/hooks';
import { KeyboardHandlerProvider } from '@mentimeter/editor-essentials/utils';
import {
  EditorOverviewDataHandler,
  OverviewListViewOnly,
} from '@mentimeter/editor-overview-data-handlers';
import { useActiveSidebarPaneStore } from '@mentimeter/editor-state';
import { trackClickedCommentIcon } from '@mentimeter/editor-tracking';
// eslint-disable-next-line menti-react/forbidden-legacy-series-import
import { TrackingContext, type Series } from '@mentimeter/http-clients';
import { useSearchParams } from '@mentimeter/next-navigation';
import { CollaborationUpgradeButton } from '@mentimeter/paywalls/buttons';
import type {
  QuestionWithSlide as PresentationQuestionWithSlide,
  SeriesWithSlideDeck,
  SlideDeck,
} from '@mentimeter/presentation-schema/api-types-overrides';
import { useMatch } from '@mentimeter/ragnar-device';
import { Box, Tooltip } from '@mentimeter/ragnar-ui';
import { useOnce } from '@mentimeter/react-hooks';
import { Invite as SfinxInviteView, ViewOnly } from '@mentimeter/sfinx';
import { useSplits } from '@mentimeter/splitio';
import { usersCacheKey, type UserT } from '@mentimeter/user';
import { useClearInitialQueryParams } from 'features/core/edit_view/application/clear-query-params';
import { EditorThemeProvider } from 'features/core/edit_view/application/EditorThemeProvider';
import {
  ADD_SLIDE_BUTTON_ID,
  AddSlideButtonComponent,
} from 'features/core/editor/Header/components/AddSlideButtonComponent';
import { HeaderViewOnly } from 'features/core/editor/Header/view-only/HeaderViewOnly';
import { SideBarViewOnly } from 'features/core/editor/Sidebar/view-only/SideBarViewOnly';
import CopyToAccount from 'features/core/presentation/shared-view/footer/CopyToAccount';
import { useEffect } from 'react';
import { SWRConfig } from 'swr';
import {
  COLLABORATOR_STATUS_COMMENT,
  type CollaboratorStatus,
} from 'utils/constants';
import { Intercom } from 'utils/Intercom';
import { useRegisterViewSeries } from 'features/core/edit_view/application/shared/use-register-view-series';
import { CanvasAreaLayout } from '../../../(collaborator)/(editor)/editor-layout-components/CanvasAreaLayout';
import { CanvasContentLayout } from '../../../(collaborator)/(editor)/editor-layout-components/CanvasContentLayout';
import { CanvasLayout } from '../../../(collaborator)/(editor)/editor-layout-components/CanvasLayout';
import { CanvasSidebarLayout } from '../../../(collaborator)/(editor)/editor-layout-components/CanvasSidebarLayout';
import { MainLayout } from '../../../(collaborator)/(editor)/editor-layout-components/MainLayout';
import { PageLayout } from '../../../(collaborator)/(editor)/editor-layout-components/PageLayout';
import { PanelsLayout } from '../../../(collaborator)/(editor)/editor-layout-components/PanelsLayout';
import { PresentationViewOnly } from '../../PresentationViewOnly';
import {
  trackClickedSlideThumbnail,
  trackViewOnlyEditor,
} from '../../trackViewOnlyEditor';
import AuthSection from '../footer/AuthSection';
import { FooterLayout } from '../footer/FooterLayout';
import PublicPresentationViewLayout from '../PublicPresentationViewLayout';

export interface ViewPageProps {
  isAuthenticated: boolean;
  user: UserT | null;
  seriesResponse:
    | { series: Series; slideDeck: SlideDeck | undefined }
    | { series: Series | undefined; slideDeck: SlideDeck };
  questionId: string;
  collaboratorStatus: CollaboratorStatus;
}

export const ViewPage = ({
  isAuthenticated,
  user,
  seriesResponse,
  questionId,
  collaboratorStatus,
}: ViewPageProps) => {
  const searchParams = useSearchParams();
  const refererSource = searchParams.get('source');

  const { TREX_View_Only_Editor, isReady } = useSplits([
    'TREX_View_Only_Editor',
  ]);
  const viewOnlyEditorSplit = TREX_View_Only_Editor === 'on';
  const slideDeckOrSeries = seriesResponse.slideDeck ?? seriesResponse.series;
  const series = createPresentationSeriesCompatibilityLayer(slideDeckOrSeries);

  const trackingContext = TrackingContext.ViewOnly;
  useOnce(true, () =>
    trackViewOnlyEditor(
      user,
      trackingContext,
      series!,
      collaboratorStatus,
      refererSource,
    ),
  );
  useClearInitialQueryParams();

  if (!isReady || !series) return null;

  const seriesId = series.id;

  return (
    <SWRConfig
      value={{
        fallback: {
          [seriesCacheKey(seriesId)]: slideDeckOrSeries,
          [questionsCacheKey(seriesId)]: seriesResponse.slideDeck
            ? seriesResponse.slideDeck.slides
            : seriesResponse.series?.questions,
          [usersCacheKey]: user,
        },
      }}
    >
      {viewOnlyEditorSplit && user ? (
        <ViewOnlyPage
          user={user}
          series={series}
          questions={series.questions}
          collaboratorStatus={collaboratorStatus}
          questionId={questionId}
          trackingContext={trackingContext}
        />
      ) : (
        <PublicViewPage
          isAuthenticated={isAuthenticated}
          user={user}
          series={series}
          questionId={questionId}
        />
      )}
    </SWRConfig>
  );
};

export const ViewOnlyPage = ({
  user,
  series,
  questions = [],
  collaboratorStatus,
  questionId,
  trackingContext,
}: {
  user: UserT;
  series: SeriesWithSlideDeck;
  questions: Array<PresentationQuestionWithSlide>;
  collaboratorStatus: CollaboratorStatus;
  questionId: string;
  trackingContext: TrackingContext;
}) => {
  const { navigateToSlide } = useEditorRouter();
  const activeQuestionId = useActiveQuestionId();

  const [activeQuestion, activeQuestionIndex] = getSlideById(
    activeQuestionId,
    questions,
  );

  useRegisterViewSeries(series.id, Boolean(user));

  const [, setActiveSidebarPane] = useActiveSidebarPaneStore();
  const shouldSidebarBeInitiallyClosed = useMatch({ lessThan: 3 });
  useEffect(() => {
    if (shouldSidebarBeInitiallyClosed) {
      setActiveSidebarPane('closed');
    }
  }, [shouldSidebarBeInitiallyClosed, setActiveSidebarPane]);

  const userIsCommentCollaborator =
    collaboratorStatus === COLLABORATOR_STATUS_COMMENT;

  const unskippedQuestions = questions.filter(
    (question) => !question.skip_slide,
  );

  return (
    <EditorThemeProvider>
      <Intercom />
      <KeyboardHandlerProvider
        questionIds={unskippedQuestions.map((question) => question.id)}
        activeQuestionIndex={activeQuestionIndex}
        activateQuestion={(questionId: string) => {
          trackClickedSlideThumbnail(
            user,
            trackingContext,
            series,
            collaboratorStatus,
          );
          navigateToSlide(questionId);
        }}
      >
        <PageLayout
          elementId="viewonly-editor-container"
          header={
            <HeaderViewOnly
              seriesId={series.id}
              seriesName={series.name}
              seriesWorkspaceId={series.workspace_id}
              trackingContext={trackingContext}
              userIsCollaborator={userIsCommentCollaborator}
              user={user}
            />
          }
          content={
            <MainLayout>
              <PanelsLayout>
                <EditorOverviewDataHandler
                  amountOfSlides={unskippedQuestions.length}
                  activeSlideIndex={activeQuestionIndex}
                >
                  <Box width="100%" p="space4" alignItems="stretch">
                    <Box alignItems="inherit" gap={2} pl="space3">
                      <Tooltip
                        placement="right"
                        referenceId={ADD_SLIDE_BUTTON_ID}
                        description={VIEW_ONLY_DISABLED_MESSAGE}
                      />
                      <AddSlideButtonComponent
                        disabled={true}
                        handleClickAddSlideButton={() => {}}
                        showAddSlidePopover={false}
                        buttonExtend={{}}
                      />
                    </Box>
                  </Box>
                  <OverviewListViewOnly
                    seriesTheme={series.theme}
                    activeQuestionId={activeQuestionId}
                    overviewListItems={
                      // NOTE: "as unknown" to work around a type cast in itself is wrong and causes other issues
                      unskippedQuestions as unknown as Parameters<
                        typeof OverviewListViewOnly
                      >[0]['overviewListItems']
                    }
                    userIsViewCollaborator={userIsCommentCollaborator}
                    onClickSlideThumbnail={() => {
                      trackClickedSlideThumbnail(
                        user,
                        trackingContext,
                        series,
                        collaboratorStatus,
                      );
                    }}
                    onClickCommentIcon={() => {
                      setActiveSidebarPane('comments');
                      trackClickedCommentIcon({
                        role: 'Collaborator',
                        context: trackingContext,
                        seriesId: series.id,
                        isSharedPresentation: series.co_edited,
                        hasResults: series.has_results,
                        permission: collaboratorStatus,
                      });
                    }}
                  />
                </EditorOverviewDataHandler>
                <CanvasSidebarLayout>
                  <CanvasAreaLayout>
                    <CanvasLayout>
                      <CanvasContentLayout>
                        <PresentationViewOnly
                          series={series}
                          questionId={questionId}
                          mode="view"
                          isViewOnlyEditor
                        >
                          <ViewOnly />
                        </PresentationViewOnly>
                      </CanvasContentLayout>
                    </CanvasLayout>
                  </CanvasAreaLayout>
                  {activeQuestion && (
                    <SideBarViewOnly
                      user={user}
                      workspaceId={series.workspace_id}
                      activeQuestionId={activeQuestion.id}
                      activeQuestionModuleId={activeQuestion.module_id}
                      activeQuestionAdminKey={activeQuestion.admin_key}
                      userIsViewCollaborator={userIsCommentCollaborator}
                    />
                  )}
                </CanvasSidebarLayout>
              </PanelsLayout>
            </MainLayout>
          }
        />
      </KeyboardHandlerProvider>
    </EditorThemeProvider>
  );
};

const PublicViewPage = ({
  isAuthenticated,
  user,
  series,
  questionId,
}: {
  isAuthenticated: boolean;
  user: UserT | null;
  series: SeriesWithSlideDeck;
  questionId: string;
}) => {
  return (
    <PublicPresentationViewLayout>
      <EditorThemeProvider>
        <PresentationViewOnly
          series={series}
          questionId={questionId}
          mode="view"
        >
          <SfinxInviteView />
        </PresentationViewOnly>
        <Box width="100%" bg="bg" p={3}>
          {isAuthenticated ? (
            <FooterLayout title={`Shared presentation "${series.name}"`}>
              <Box flexDirection="row" gap={2}>
                <CopyToAccount
                  trackingContext={TrackingContext.PresentationSharedView}
                />
                <CollaborationUpgradeButton
                  trackingContext={TrackingContext.PresentationSharedView}
                  trackingPlacement="Footer actions"
                  user={user}
                />
              </Box>
            </FooterLayout>
          ) : (
            <FooterLayout title="Create a free Mentimeter account to use this presentation">
              <AuthSection />
            </FooterLayout>
          )}
        </Box>
      </EditorThemeProvider>
    </PublicPresentationViewLayout>
  );
};
