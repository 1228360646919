'use client';

import { TrackingContext } from '@mentimeter/http-clients';
import { IntercomScript } from '@mentimeter/intercom';
import { trackUser } from '@api/tracking/client';

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID as string;
const INTERCOM_PLACEMENT = 'Knowledge hub menu';

export function Intercom() {
  return (
    <IntercomScript
      onOpenIntercom={trackOpenedIntercom}
      onCloseIntercom={trackClosedIntercom}
      // each page decides on loading the Bubble component itself
      hideLauncher={true}
      intercomAppId={INTERCOM_APP_ID}
    />
  );
}

function trackOpenedIntercom() {
  trackUser({
    event: 'Opened Intercom',
    properties: {
      placement: INTERCOM_PLACEMENT,
      context: TrackingContext.Editview,
    },
  });
}

function trackClosedIntercom() {
  trackUser({
    event: 'Closed Intercom',
    properties: {
      placement: INTERCOM_PLACEMENT,
      context: TrackingContext.Editview,
    },
  });
}
