import * as React from 'react';
import {
  Box,
  Hotkey,
  Text,
  ThemeMentiLight,
  TooltipGeneric,
} from '@mentimeter/ragnar-ui';

interface TooltipT {
  placement: 'top' | 'right' | 'left';
  id: string;
  label: string;
  hotkey?: string | undefined;
  richTooltipText?: string | undefined;
  richTooltipImage?: React.ComponentType<any> | undefined;
  portalId?: string;
}

const Tooltip = ({
  richTooltipImage: RichTooltipImage,
  richTooltipText,
  placement,
  id,
  label,
  hotkey,
  portalId,
}: TooltipT) => {
  return (
    <ThemeMentiLight>
      <TooltipGeneric
        delay={0}
        placement={placement}
        referenceId={id}
        domId={`${portalId}${id}`}
        ignorePointer
      >
        <Box
          minHeight="40px"
          display="flex"
          justifyContent="center"
          bg="highContrast"
          borderRadius="2xl"
        >
          {RichTooltipImage && (
            <Box maxWidth="225px">
              <RichTooltipImage />
            </Box>
          )}

          <Box
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            display="flex"
            flexDirection="row"
            px="space4"
            py="space2"
          >
            <Text
              fontSize={1}
              mr={hotkey ? 'space4' : 'space0'}
              color="onHighContrast"
              extend={() => ({
                whiteSpace: 'nowrap',
              })}
            >
              {label}
            </Text>

            {hotkey && <Hotkey>{hotkey.toUpperCase()}</Hotkey>}
          </Box>
          {richTooltipText && (
            <Text
              color="text"
              lineHeight="heading"
              textAlign="initial"
              px="space4"
              pb="space4"
              fontSize="14px"
            >
              {richTooltipText}
            </Text>
          )}
        </Box>
      </TooltipGeneric>
    </ThemeMentiLight>
  );
};
export default Tooltip;
