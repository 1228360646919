'use client';

import React from 'react';
import type { TrackingContext } from '@mentimeter/http-clients';
import { UserFeaturesTypeEnum } from '@mentimeter/http-clients';
import type { UpgradeButtonTrackingPlacement } from '@mentimeter/paywalls';
import type { UserT } from '@mentimeter/user';
import { StarSolidIcon } from '@mentimeter/ragnar-visuals';
import { trackUser } from '@api/tracking/client';
import {
  RequestUpgradeModal,
  RequestUpgradeButton,
} from '../lib/request-upgrade';
import { useFeatureAccess } from '../lib/hooks/use-feature-access';
import { UpgradeButton } from './UpgradeButton';

const requestUpgradeTooltipDescription =
  'To collaborate on this presentation with your teammates, your role needs to be upgraded by an admin.';
const requestUpgradeTooltipTitle =
  'Interested in co-editing this presentation with others?';

export const CollaborationUpgradeButton = ({
  size = 'default',
  trackingContext,
  trackingPlacement,
  user,
}: {
  size?: 'default' | 'compact';
  user: UserT | null;
  trackingContext: TrackingContext | undefined;
  trackingPlacement: UpgradeButtonTrackingPlacement;
}) => {
  const accessLevel = useFeatureAccess(UserFeaturesTypeEnum.PRO);

  if (!user || accessLevel === 'has-access') return null;

  if (accessLevel === 'not-in-role') {
    return (
      <RequestUpgradeModal
        trackingContext={trackingContext}
        trackingPlacement={trackingPlacement}
        description={requestUpgradeTooltipDescription}
        title={requestUpgradeTooltipTitle}
      >
        <RequestUpgradeButton
          size={size}
          variant="primary"
          iconLeading={StarSolidIcon}
        />
      </RequestUpgradeModal>
    );
  } else {
    return (
      <UpgradeButton
        size={size}
        text="Upgrade"
        variant="primary"
        onClick={() => {
          trackUser({
            event: 'Plans from Invite view - collaboration',
          });
        }}
      />
    );
  }
};
