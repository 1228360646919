import { Box } from '@mentimeter/ragnar-ui';
import { NavigationActions } from '..';
import { PresentationCanvas } from '../sfonx/slide-layout/PresentationCanvas';
import Gestures from '../sfonx/canvas/Gestures';
import ProgressIndicator from '../sfonx/misc/ProgressIndicator';
import { InternalProviders } from '../sfonx/misc/internal-providers';
import { Container } from '../sfonx/canvas/Container';
import { SlideWrapper } from '../sfonx/slide-layout/SlideWrapper';
import Module from '../sfonx/misc/Module';
import Content from '../sfonx/slide-layout/Content';
import { Indications } from '../sfonx/toolbar/indicators/Indications';
import { CfaIndicator } from '../sfonx/toolbar/indicators/cfa/CfaIndicator';
import { QfaIndicator } from '../sfonx/toolbar/indicators/qfa/QfaIndicator';
import { VoteIndicator } from '../sfonx/toolbar/indicators/VoteIndicator';
import { Reactions } from '../sfonx/toolbar/indicators/reactions';
import { useQuestion } from '../sfonx/data-mediation';
import { BOX_SHADOW } from './utils';

export const InvitePresentation = () => {
  const { theme_settings } = useQuestion();

  return (
    <Container>
      {(style) => (
        <div style={style}>
          <Box
            borderRadius={2}
            overflow="hidden"
            width="100%"
            height="100%"
            extend={BOX_SHADOW}
          >
            <PresentationCanvas
              slideImage={theme_settings?.background_cropped_image_url}
            >
              <Box
                width="100%"
                height="100%"
                flex="1 1 auto"
                zIndex={1}
                flexDirection="row"
              >
                <Gestures>
                  <Content>
                    <SlideWrapper>
                      <Module />
                    </SlideWrapper>
                  </Content>
                  <Indications>
                    <Reactions />
                    <CfaIndicator />
                    <QfaIndicator />
                    <VoteIndicator />
                  </Indications>
                </Gestures>
              </Box>
              <ProgressIndicator />
            </PresentationCanvas>
            <NavigationActions />
          </Box>
        </div>
      )}
    </Container>
  );
};

export const Invite = () => (
  <InternalProviders renderFallbackOnError={false}>
    <InvitePresentation />
  </InternalProviders>
);
