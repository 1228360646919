import { cva, type VariantProps } from '@mentimeter/ragnar-tailwind-config';

export const hotkey = cva(
  ['h-[24px]', 'min-w-[24px]', 'items-center', 'justify-center', 'rounded-lg'],
  {
    variants: {
      checked: {
        true: 'bg-secondary text-on-secondary',
        false: 'bg-neutral-weakest text-on-neutral-weakest',
      },
      hasIcon: {
        true: 'px-0',
        false: 'px-1.5',
      },
    },
    defaultVariants: {
      checked: false,
      hasIcon: false,
    },
  },
);

export type HotkeyVariantProps = VariantProps<typeof hotkey>;
