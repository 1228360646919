import { HeaderIconButton } from '@mentimeter/editor-header/src/components/UI/HeaderIconButton';
import type { TrackingContext } from '@mentimeter/http-clients';
import { Tooltip, type IconButtonProps } from '@mentimeter/ragnar-ui';
import { CopyIcon } from '@mentimeter/ragnar-visuals';
import { useCopyToAccount } from 'features/core/presentation/shared-view/footer/CopyToAccount';

export const CopyToAccount = ({
  trackingContext,
  buttonSize,
}: {
  trackingContext: TrackingContext;
  buttonSize: IconButtonProps['size'];
}) => {
  const { state: copyPresentationState, copyPresentationToAccount } =
    useCopyToAccount(trackingContext);

  return (
    <>
      <HeaderIconButton
        id="copy-to-my-presentations-button"
        size={buttonSize}
        onClick={copyPresentationToAccount}
        variant="secondary"
        state={copyPresentationState}
      >
        <CopyIcon />
      </HeaderIconButton>
      <Tooltip
        referenceId="copy-to-my-presentations-button"
        description={
          copyPresentationState === 'success'
            ? 'Copied'
            : 'Copy to My presentations'
        }
        placement="bottom-start"
      />
    </>
  );
};
