'use client';

import { useAppRouterIds } from '@mentimeter/core-hooks/use-ids';
import { AspectRatioContainer } from '@mentimeter/editor-canvas';
import type { SeriesWithSlideDeck as Series } from '@mentimeter/presentation-schema/api-types-overrides';
import type { PresentationMode } from '@mentimeter/presentation-url-utils';
import { Box, Ratio } from '@mentimeter/ragnar-ui';
import { EditorCanvasAreaSlideLayout } from 'features/core/edit_view/application/EditorCanvasAreaSlideLayout';
import { ConfigProvider } from 'features/core/presentation/shared/config';
import { setupRegion } from 'lib/http/region-setup';
import { PresentationContainer } from './PresentationContainer';

export const PresentationViewOnly = ({
  series,
  questionId,
  mode,
  isViewOnlyEditor,
  children,
}: {
  series: Series;
  questionId: string;
  mode: PresentationMode;
  isViewOnlyEditor?: boolean;
  children: React.ReactNode;
}) => {
  setupRegion(series.id);
  useAppRouterIds({ seriesId: series.id, questionId });

  if (isViewOnlyEditor) {
    return (
      <AspectRatioContainer>
        <ConfigProvider>
          <PresentationContainer
            series={series}
            questionId={questionId}
            mode={mode}
          >
            <EditorCanvasAreaSlideLayout
              isCanvasInteractive
              isSlideActiveAndSidebarClosed
            >
              {children}
            </EditorCanvasAreaSlideLayout>
          </PresentationContainer>
        </ConfigProvider>
      </AspectRatioContainer>
    );
  }

  return (
    <ConfigProvider>
      <PresentationContainer
        series={series}
        questionId={questionId}
        mode={mode}
      >
        <Box
          bg="black"
          flex="1 1 auto"
          width="100%"
          height="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          px={3}
        >
          <Box width={['100%', '57%', '75%']}>
            <Ratio ratio="landscape">{children}</Ratio>
          </Box>
        </Box>
      </PresentationContainer>
    </ConfigProvider>
  );
};
