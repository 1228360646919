'use client';

import AuthModalClicker from 'components/AuthModalClicker';
import { Button } from '@mentimeter/ragnar-ui';
import { useMatch } from '@mentimeter/ragnar-device';
import { useReferral } from '@mentimeter/auth';
import { usePathname, useSearchParams } from '@mentimeter/next-navigation';

const AuthSection = () => {
  const isTablet = useMatch({ lessThan: 3 });
  const referral = useReferral();

  const searchParams = useSearchParams();
  const pathName = usePathname();

  const continueUrl = `${pathName}?${searchParams?.toString()}`;

  return (
    <>
      <AuthModalClicker
        showSignup={false}
        continueUrl={continueUrl}
        referral={referral}
        render={({ onClick }) => (
          <Button
            variant="outline"
            size={isTablet ? 'default' : 'large'}
            width={isTablet ? '150px' : 'auto'}
            mr={3}
            onClick={onClick}
          >
            Log In
          </Button>
        )}
      />
      <AuthModalClicker
        showSignup
        continueUrl={continueUrl}
        referral="try-it-for-free-button"
        render={({ onClick }) => (
          <Button
            variant="primary"
            size={isTablet ? 'default' : 'large'}
            width={isTablet ? '150px' : 'auto'}
            onClick={onClick}
          >
            Sign up
          </Button>
        )}
      />
    </>
  );
};

export default AuthSection;
