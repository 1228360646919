import { Box } from '@mentimeter/ragnar-ui';
import Header from 'features/core/presentation/shared-view/Header';
import type { PropsWithChildren } from 'react';

export default function PublicPresentationViewLayout({
  children,
}: PropsWithChildren) {
  return (
    <Box data-testid="public-presentation-view" width="100%" height="100%">
      <Box display={['flex', 'none']} width="100%" bg="bg" p={3}>
        <Header />
      </Box>
      {children}
    </Box>
  );
}
