import { Box } from '@mentimeter/ragnar-ui';
import { PresentationCanvas } from '../sfonx/slide-layout/PresentationCanvas';
import { InternalProviders } from '../sfonx/misc/internal-providers';
import { Container } from '../sfonx/canvas/Container';
import { SlideWrapper } from '../sfonx/slide-layout/SlideWrapper';
import Module from '../sfonx/misc/Module';
import Content from '../sfonx/slide-layout/Content';
import { Indications } from '../sfonx/toolbar/indicators/Indications';
import { CfaIndicator } from '../sfonx/toolbar/indicators/cfa/CfaIndicator';
import { QfaIndicator } from '../sfonx/toolbar/indicators/qfa/QfaIndicator';
import { VoteIndicator } from '../sfonx/toolbar/indicators/VoteIndicator';
import { Reactions } from '../sfonx/toolbar/indicators/reactions';
import { useQuestion } from '../sfonx/data-mediation';

const ViewOnlyPresentation = () => {
  const { theme_settings } = useQuestion();

  return (
    <Container transformOrigin="top center">
      {(style) => (
        <div
          style={{
            position: 'absolute',
            ...style,
          }}
          data-testid="sfinx-wrapper"
          id="preview-step--id"
        >
          <PresentationCanvas
            slideImage={theme_settings?.background_cropped_image_url}
          >
            <Box
              width="100%"
              height="100%"
              flex="1 1 auto"
              zIndex={1}
              flexDirection="row"
            >
              <Content>
                <SlideWrapper>
                  <Module />
                </SlideWrapper>
              </Content>
              <Indications>
                <Reactions />
                <CfaIndicator />
                <QfaIndicator />
                <VoteIndicator />
              </Indications>
            </Box>
          </PresentationCanvas>
        </div>
      )}
    </Container>
  );
};

export const ViewOnly = () => (
  <InternalProviders renderFallbackOnError={false}>
    <ViewOnlyPresentation />
  </InternalProviders>
);
