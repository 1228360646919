import React from 'react';
import { Box } from '../Box';
import { Text } from '../Text';
import { hotkey, type HotkeyVariantProps } from './Hotkey.variants';

export interface HotkeyT extends HotkeyVariantProps {
  children?: React.ReactNode;
}

export const Hotkey = ({ children, checked, hasIcon }: HotkeyT) => (
  <Box className={hotkey({ checked, hasIcon })}>
    {hasIcon ? (
      children
    ) : (
      <Text className="text-inherit font-semibold">{children}</Text>
    )}
  </Box>
);
