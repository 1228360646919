'use client';

import { MentimeterLogoType } from '@mentimeter/ragnar-visuals';
import { Clickable } from '@mentimeter/ragnar-ui';
import { useMatch } from '@mentimeter/ragnar-device';

const Header = () => {
  const isTablet = useMatch({ lessThan: 3 });
  return (
    <Clickable href="/" aria-label="Link to mentimeter.com" mr={4}>
      <MentimeterLogoType height={isTablet ? 16 : 20} />
    </Clickable>
  );
};

export default Header;
