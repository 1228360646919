import { AuthModalClicker as Clicker } from '@mentimeter/auth';
import authPackage from '../features/authPackage';

interface RenderPropsT {
  onClick: () => void;
}
interface PropsT {
  showSignup: boolean;
  continueUrl?: string;
  referral: string;
  render: (props: RenderPropsT) => any;
}
const AuthModalClicker = ({
  showSignup,
  continueUrl,
  referral,
  render,
}: PropsT) => (
  <Clicker
    referral={referral}
    authPackage={authPackage}
    showSignup={showSignup}
    continueUrl={continueUrl}
    render={({ onClick }) => render({ onClick })}
  />
);

export default AuthModalClicker;
