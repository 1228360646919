'use client';

import * as React from 'react';
import type { ButtonT } from '@mentimeter/ragnar-ui';
import { Button } from '@mentimeter/ragnar-ui';
import { useActiveSeriesId } from '@mentimeter/core-hooks/use-ids';
import { DEFAULT_REGION, getRegionBySeriesId } from '@mentimeter/region';
import { userCache, useUser, type UseUserT } from '@mentimeter/user';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { postRecentRequest } from '@core-api/presentation-collection/presentation-collection/recent';
import { core, type DuplicateSeriesPayload } from '@mentimeter/http-clients';

interface PropsT extends ButtonT {
  trackingContext: string;
}

function onGoToHomeView() {
  // We cannot use the Next.js in-page router here, because there's a problematic behaviour
  // in which a redux middleware triggers a redirect back to prez view with and undefined seriesId.
  window.location.assign('/app');
}

const duplicateSeries = async (
  { isAuthenticated, user }: Pick<UseUserT, 'isAuthenticated' | 'user'>,
  payload: DuplicateSeriesPayload,
) => {
  if (!isAuthenticated || !user) {
    window.location.assign('/auth/login');
    return Promise.reject(new Error('Unauthorized'));
  }

  const coreClient = core({ region: user.region || DEFAULT_REGION });
  const { data } = await coreClient.duplicate.series(payload);

  return {
    seriesId: data.id,
    // @ts-expect-error-auto TS(2532) FIXME: Object is possibly 'undefined'.
    questionId: data.questions[0].id,
  };
};

export const useCopyToAccount = (trackingContext: string) => {
  const seriesId = useActiveSeriesId();
  const [state, setState] = React.useState<ButtonT['state']>();
  const { user, isAuthenticated } = useUser();

  const copyPresentationToAccount = React.useCallback(async () => {
    if (state !== undefined) return;

    setState('loading');
    try {
      const { seriesId: newSeriesId } = await duplicateSeries(
        { user, isAuthenticated },
        {
          copy: seriesId,
          track_context: trackingContext,
          region: getRegionBySeriesId(seriesId),
        },
      );
      // Ensure the newly copied series shows up as the most recent one
      await registerSeriesAsRecentlyView(newSeriesId);
      setState('success');
      setTimeout(() => setState(undefined), 3000);
    } catch {
      setState('error');
      setTimeout(() => setState(undefined), 3000);
    }
  }, [state, seriesId, user, isAuthenticated, trackingContext]);

  return { state, copyPresentationToAccount };
};

export default function CopyToAccount({
  trackingContext,
  ...buttonProps
}: PropsT) {
  const { state, copyPresentationToAccount } =
    useCopyToAccount(trackingContext);

  function onClickCopy(e: React.MouseEvent) {
    if (state === 'success') {
      onGoToHomeView();
    } else {
      copyPresentationToAccount();
    }
  }

  return (
    <Button
      variant="outline"
      onClick={onClickCopy}
      state={state === 'success' ? undefined : state}
      {...buttonProps}
    >
      {state === 'success'
        ? 'Go to your presentations'
        : 'Copy to your account'}
    </Button>
  );
}

async function registerSeriesAsRecentlyView(seriesId: string) {
  const region = userCache.region;
  const sessionToken = userCache.getToken();

  const response = await fetch(
    postRecentRequest({ region, userAuth: sessionToken, id: seriesId }),
  );
  if (!response.ok && response.status !== 401) {
    const e = new MentiError('Failed to register cloned series', {
      feature: 'recent-presentations',
    });
    captureException(e);
  }
}
