'use client';

import { MentimeterLogoType } from '@mentimeter/ragnar-visuals';
import { Box, Clickable, Text } from '@mentimeter/ragnar-ui';
import { useMatch } from '@mentimeter/ragnar-device';
import type { PropsWithChildren } from 'react';
import { MentimeterI18nInitializer, i18nextConfig } from '@mentimeter/i18n';

interface PropsT {
  title: string;
  hideLogo?: boolean;
}

export function FooterLayout({
  title,
  hideLogo = false,
  children,
}: PropsWithChildren<PropsT>) {
  const isTablet = useMatch({ lessThan: 3 });
  const isMobile = useMatch({ lessThan: 2 });
  return (
    <MentimeterI18nInitializer config={i18nextConfig}>
      <Box
        width="100%"
        justifyContent="space-between"
        flexDirection={['column', 'row', 'row']}
        gap={2}
        alignItems="center"
      >
        <Box flex="1 1 auto" flexDirection="row" alignItems="baseline">
          {!hideLogo && (
            <Clickable
              display={['none', 'flex']}
              href="/"
              aria-label="Link to mentimeter.com"
              mr={4}
            >
              <MentimeterLogoType height={20} />
            </Clickable>
          )}

          <Text
            textAlign={['center', 'left']}
            color="text"
            fontWeight="semiBold"
            fontFamily="heading"
            fontSize={isTablet ? 3 : 4}
            truncate={!isMobile}
            m={0}
          >
            {title}
          </Text>
        </Box>
        <Box ml={[0, 4]} flexDirection="row">
          {children}
        </Box>
      </Box>
    </MentimeterI18nInitializer>
  );
}
