import type { EditorErrors } from '@mentimeter/core-global-state';
import {
  EditorFlags,
  useEditorError,
  useEditorFlag,
  useIsPersisting,
  useLastRequestTimestamp,
  useUserPresentationRole,
} from '@mentimeter/core-global-state';
import {
  HeaderBack,
  HeaderCta,
  HeaderBadge,
  HeaderLayout,
  HeaderPresentButton,
  HeaderPresentButtonDropdown,
  HeaderPreviewButton,
  HeaderSeparator,
  HeaderSettingsButton,
  HeaderShareButton,
  HeaderTabs,
  useLastPresentTarget,
  HeaderInviteTeamMemberButton,
} from '@mentimeter/editor-header';
import { ErrorBoundary } from '@mentimeter/errors/ErrorBoundary';
import { tracking, type TrackingContext } from '@mentimeter/http-clients';
import { EngagementLimitPresentPaywallDataHandler } from '@mentimeter/paywalls/engagement-limit';
import { Device, useDevice } from '@mentimeter/ragnar-device';
import { Box, TextEllipsis, Tooltip } from '@mentimeter/ragnar-ui';
import { useIsOffline } from '@mentimeter/react-hooks';
import { useReturnTo } from '@mentimeter/return-to';
import { type UserT } from '@mentimeter/user';
import { CollaborationUpgradeButton } from '@mentimeter/paywalls/buttons';
import { Suspense, useId, useMemo } from 'react';
import { VIEW_ONLY_DISABLED_MESSAGE } from '@mentimeter/editor-essentials/constants';
import { getVisitorToken, useSplits } from '@mentimeter/splitio';
import { trackBackButton } from '../tracking';
import { CopyToAccount } from './CopyToAccount';

interface Props {
  trackingContext: TrackingContext;
  userIsCollaborator: boolean;
  user: UserT | null;
  seriesId: string;
  seriesName: string;
  seriesWorkspaceId: number | null;
}

export const HeaderViewOnly = ({
  trackingContext,
  userIsCollaborator,
  user,
  seriesId,
  seriesName,
  seriesWorkspaceId,
}: Props) => {
  const userRole = useUserPresentationRole();
  const isOffline = useIsOffline();
  const isPersisting = useIsPersisting();
  const lastRequestMs = useLastRequestTimestamp();

  const [editorError, setEditorError] = useEditorError<EditorErrors>();
  const [isUpdating] = useEditorFlag(EditorFlags.IsUpdating);
  const [lastTarget] = useLastPresentTarget();

  const { clearReturnToValues, returnHref, returnLabel } =
    useReturnTo('back-from-editor');

  const hasUser = Boolean(user);

  const { backHref, backLabel } = useMemo(() => {
    if (!hasUser) {
      return {
        backHref: '/',
        backLabel: 'Sign up',
      };
    }

    const baseText = 'Back to';
    const isComingFromPage = Boolean(returnHref) && Boolean(returnLabel);

    const defaultBackUrl = '/app/home';
    const defaultBackLabel = 'Home';

    const href = isComingFromPage ? returnHref : defaultBackUrl;
    const label = isComingFromPage ? returnLabel : defaultBackLabel;

    return {
      backHref: href!,
      backLabel: `${baseText} ${label}`,
    };
  }, [returnHref, returnLabel, hasUser]);

  const onGoBack = () => {
    if (user) {
      trackBackButton(trackingContext);
    } else {
      const visitorToken = getVisitorToken();
      if (!visitorToken) return;

      tracking().trackVisitor(
        {
          event: 'Clicked sign up',
          properties: {
            context: trackingContext,
          },
        },
        visitorToken as string,
      );
    }

    clearReturnToValues();
  };

  const workspaceRole = user?.role;

  const presentButtonId = useId();

  const { INT_Persistent_Present_Button } = useSplits([
    'INT_Persistent_Present_Button',
  ]);
  const persistingTargetEnabled = INT_Persistent_Present_Button === 'on';

  const { breakpoint } = useDevice();

  const buttonSize = breakpoint > 3 ? 'default' : 'compact';

  return (
    <HeaderLayout>
      <HeaderLayout.Left>
        <HeaderBack
          backLinkHref={backHref}
          tooltipText={backLabel}
          onBackLinkClick={onGoBack}
          size={buttonSize}
          showLogo={!hasUser}
        >
          <Box
            maxWidth={breakpoint > 3 ? 320 : 200}
            flexDirection="row"
            alignItems="center"
            gap="space1"
          >
            <TextEllipsis
              id={`menti-name-${seriesId}`}
              fontSize="87.5"
              fontWeight="semiBold"
              text={seriesName}
              tooltipDescription={seriesName}
            />
            {hasUser && (
              <CopyToAccount
                trackingContext={trackingContext}
                buttonSize={buttonSize}
              />
            )}
          </Box>
          <Device.Match greaterThan={1}>
            <HeaderBadge
              text="View only"
              bg="brandWeakest"
              ml="space2"
              textTransform="none"
            />
            <HeaderSeparator />
            <HeaderSettingsButton
              ml="space1"
              size={buttonSize}
              label={VIEW_ONLY_DISABLED_MESSAGE}
              disabled
            />
          </Device.Match>

          <HeaderBack.Status
            isOffline={isOffline}
            isUpdatingOrPersisting={isUpdating || isPersisting}
            resetError={() => setEditorError(undefined)}
            error={editorError}
            lastRequestMs={lastRequestMs}
          />
        </HeaderBack>
      </HeaderLayout.Left>

      <Device.Match greaterThan={2}>
        <HeaderLayout.Middle>
          <HeaderTabs
            seriesId={seriesId}
            workspaceId={seriesWorkspaceId}
            userRole={userRole}
            workspaceRole={workspaceRole}
            viewOnly
          />
          <Tooltip
            referenceId="results"
            description={VIEW_ONLY_DISABLED_MESSAGE}
            placement="bottom"
          />
        </HeaderLayout.Middle>
      </Device.Match>

      <HeaderLayout.Right>
        <Device.Match greaterThan={3}>
          <HeaderInviteTeamMemberButton
            onClick={() => {}}
            size={buttonSize}
            disabled={true}
          />
          <HeaderSeparator />
        </Device.Match>

        <Box flexDirection="row" alignItems="center" gap={2}>
          <Device.Match greaterThan={3}>
            <HeaderPreviewButton
              onClick={() => {}}
              size={buttonSize}
              label={VIEW_ONLY_DISABLED_MESSAGE}
              disabled
            />
            <HeaderShareButton
              id="header-share-button"
              size={buttonSize}
              disabled
            />
            <Tooltip
              referenceId="header-share-button"
              description={VIEW_ONLY_DISABLED_MESSAGE}
              placement="bottom"
            />
          </Device.Match>

          <EngagementLimitPresentPaywallDataHandler seriesId={seriesId}>
            <ErrorBoundary
              feature="creation-experience"
              errorMessage="Could not load Present button in Header"
            >
              <Suspense fallback={null}>
                <Device.Match greaterThan={1}>
                  <HeaderCta>
                    <HeaderPresentButton
                      buttonReferenceId={presentButtonId}
                      persistingTargetEnabled={persistingTargetEnabled}
                      lastTarget={lastTarget}
                      size={buttonSize}
                      disabled
                    />
                    <HeaderPresentButtonDropdown
                      size={buttonSize}
                      persistingTargetEnabled={persistingTargetEnabled}
                      newPresentationMode={false}
                      seriesId={seriesId}
                      disabled
                    />
                    <Tooltip
                      referenceId={presentButtonId}
                      description={VIEW_ONLY_DISABLED_MESSAGE}
                      placement="bottom"
                    />
                  </HeaderCta>
                </Device.Match>
              </Suspense>
              {userIsCollaborator && (
                <CollaborationUpgradeButton
                  size={buttonSize}
                  trackingContext={trackingContext}
                  trackingPlacement="Header actions"
                  user={user}
                />
              )}
            </ErrorBoundary>
          </EngagementLimitPresentPaywallDataHandler>
        </Box>
      </HeaderLayout.Right>
    </HeaderLayout>
  );
};
